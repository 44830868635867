const palette = {
  type: "light",
  black: {
    main: "#000000",
  },
  white: {
    main: "#ffffff",
  },
  primary: {
    main: "#1AB21A",
  },
  secondary: {
    main: "#E3E3E8",
  },
  error: {
    main: "#FF0000",
  },
  success: {
    main: "#1EDF0D",
  },
  background: {
    default: "#F1F3F4",
    paper: "#FFFEFF",
    light: "#F5F6FF",
  },
  text: {
    primary: "#1E222A",
    secondary: "#717685",
    hover: "rgba(34,34,34,0.7)",
    dark: {
      primary: "#FFFFFF",
    },
  },
};

export default palette;
