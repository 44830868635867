import Container from "@mui/material/Container";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageLocale } from "./helper/LocalStorageHelper";
import { cartItemsState, updateCartState } from "./reducers/shoppingCartSlice";
import Router from "./router";

export default function App() {
  const { i18n } = useTranslation();
  const globalCartItems = useSelector(cartItemsState);
  const dispatch = useDispatch();

  useEffect(() => {
    const lang = getLocalStorageLocale();
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    try {
      // get cart items from local storage
      const cartState = JSON.parse(localStorage.getItem("cartState"));
      console.log("cartState", cartState);
      if (cartState) {
        // set cart items to redux

        dispatch(
          updateCartState({
            cartItems: cartState.cartItems,
            originTotal: cartState.originTotal,
            finalTotal: cartState.finalTotal,
            discountPrice: cartState.discountPrice,
            orderPromotion: cartState.orderPromotion,
          })
        );
      }
    } catch (error) {
      console.log(error);
      // clear cart items in redux
      dispatch(
        updateCartState({
          cartItems: [],
          originTotal: 0,
          finalTotal: 0,
          discountPrice: 0,
          orderPromotion: {},
        })
      );
    }
  }, []);

  return (
    <Container disableGutters maxWidth={false}>
      <Router></Router>
    </Container>
  );
}
