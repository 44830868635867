import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, IconButton, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatHelper } from "../../helper/FormatHelper";
import { getVariantName } from "../../helper/UnitsHelper";
import palette from "../../styles/theme/palette";

export default function CartItem({ index, item, onUpdate }) {
  const { t } = useTranslation();
  const [priceLabel, setPriceLabel] = useState("");

  useEffect(() => {}, []);

  const addQty = () => {
    onUpdate(index, item, item.qty + 1);
  };

  const minusQty = () => {
    onUpdate(index, item, item.qty - 1);
  };

  return (
    <ListItem disableGutters divider sx={{ width: "100%", py: 2 }}>
      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Box display={"flex"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              pr: 2,
            }}
          >
            <IconButton
              onClick={() => {
                minusQty();
              }}
            >
              <RemoveCircleIcon color="primary" />
            </IconButton>

            <Typography variant="h4" color="primary">
              {item.qty}
            </Typography>
            <IconButton
              color="primary"
              onClick={() => {
                addQty();
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant={"h4"}>{item.product.title}</Typography>
            <Typography
              variant={"p2"}
              color={palette.text.secondary}
              sx={{
                whiteSpace: "break-spaces",
              }}
            >
              {getVariantName(item)}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant={"h4"} sx={{ wordBreak: "keep-all" }}>
            {FormatHelper.formatAmount(item.finalPrice)}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
}
