import Category from "../views/Category";
import Products from "../views/Products";
import ShoppingCart from "../views/ShoppingCart";
import Layout from "../components/Layout";
import OrderList from "../views/OrderList";
import Contact from "../views/Contact";

const MainRoute = {
  element: <Layout />,
  children: [
    {
      path: "/category",
      element: <Category />,
    },
    {
      path: "/products",
      element: <Products />,
    },
    {
      path: "/shopping-cart",
      element: <ShoppingCart />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "order-list",
      element: <OrderList />,
    },
  ],
};

export default MainRoute;
