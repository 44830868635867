import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";

export default function CategorySkeleton({ row, colSpan }) {
  const [skeletonList, setSkeletonList] = useState([]);

  useEffect(() => {}, []);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Skeleton
        height={150}
        sx={{ mx: 1, mt: 1 }}
        variant="rounded"
        animation="wave"
      />
      <Skeleton
        height={150}
        sx={{ mx: 1 }}
        variant="rounded"
        animation="wave"
      />
      <Skeleton
        height={150}
        sx={{ mx: 1 }}
        variant="rounded"
        animation="wave"
      />
      <Skeleton
        height={150}
        sx={{ mx: 1 }}
        variant="rounded"
        animation="wave"
      />
    </Box>
  );
}
