import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  cartItemsState,
  discountPriceState,
  finalTotalState,
  orderPromotionState,
  originTotalState,
} from "../../reducers/shoppingCartSlice";
import palette from "../../styles/theme/palette";

export default function ActionButton({ cart, contact, checkout, onSubmit }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");
  const storeId = searchParams.get("store_id");
  const [isHidden, setIsHidden] = useState(false);

  const globalCartItems = useSelector(cartItemsState);
  const globalOriginTotal = useSelector(originTotalState);
  const globalFinalTotal = useSelector(finalTotalState);
  const globalDiscountPrice = useSelector(discountPriceState);
  const globalOrderPromotion = useSelector(orderPromotionState);

  useEffect(() => {}, []);

  useEffect(() => {
    if (globalCartItems.length == 0) return;
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [globalCartItems]);

  const getCartItemsCount = () => {
    let count = 0;
    globalCartItems.forEach((item) => {
      count += item.qty;
    });
    return count;
  };

  const handleSubmit = async () => {
    let data = null;

    if (storeId) {
      data = {
        store_id: storeId,
      };
    } else if (orderId) {
      data = {
        order_id: orderId,
      };
    }

    if (contact) {
      viewContact(data);
    } else if (checkout) {
      submitOrder();
    } else {
      viewShoppingCart(data);
    }
  };

  const viewShoppingCart = (data) => {
    navigate({
      pathname: "/shopping-cart",
      search: createSearchParams(data).toString(),
    });
  };

  const viewContact = (data) => {
    navigate({
      pathname: "/contact",
      search: createSearchParams(data).toString(),
    });
  };

  const submitOrder = async () => {
    try {
      setIsHidden(true);
      await onSubmit();
    } catch (error) {
      console.log(error);
    } finally {
      setIsHidden(false);
    }
  };

  return (
    <Box
      sx={{
        display: isHidden || globalCartItems.length == 0 ? "none" : "flex",
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignItems: "center",
        // zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      />
      <Box
        sx={{
          backgroundColor: palette.primary.main,
          boxShadow: 1,
          px: 2,
          py: 4,
          flexGrow: 1,
          gap: 2,
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Button
          fullWidth
          variant={"contained"}
          onClick={() => {
            handleSubmit();
          }}
          size="large"
          sx={{
            color: palette.primary.main,
            backgroundColor: palette.background.paper,
            justifyContent: "space-between",
            // fix safari hover bug
            "&:hover": { backgroundColor: palette.background.paper },
          }}
        >
          {cart && (
            <Typography variant="h3">
              {t("layout.view_shopping_cart")}
            </Typography>
          )}
          {contact && (
            <Typography variant="h3">{t("layout.next_step")}</Typography>
          )}
          {checkout && (
            <Typography variant="h3">{t("layout.checkout")}</Typography>
          )}
          <ArrowForwardIcon />
        </Button>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={32}
              sx={{ color: palette.background.paper }}
            />
          ) : (
            <Chip
              label={getCartItemsCount()}
              sx={{
                color: palette.primary.main,
                backgroundColor: palette.background.paper,
              }}
            />
          )}

          <Typography variant="h4" color={palette.background.paper}>
            {t("layout.total")}: {globalFinalTotal}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
