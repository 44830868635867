import { Box, ButtonBase, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import palette from "../../styles/theme/palette";

export default function CategoryCard({ category }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");
  const storeId = searchParams.get("store_id");

  const viewDetails = () => {
    let data = null;

    if (storeId) {
      data = {
        store_id: storeId,
        category_id: category.id,
        category_name: category.name,
      };
    } else if (orderId) {
      data = {
        order_id: orderId,
        category_id: category.id,
        category_name: category.name,
      };
    }

    navigate({
      pathname: "/products",
      search: createSearchParams(data).toString(),
    });
  };

  return (
    <ButtonBase sx={{ width: "100%" }} onClick={viewDetails}>
      <Box
        sx={{ flexGrow: 1, position: "relative", height: 150, width: "100%" }}
      >
        {category.images && category.images.length > 0 ? (
          <Box
            component={"img"}
            width={"100%"}
            height={"100%"}
            borderRadius={2}
            sx={{ objectFit: "cover", borderRadius: 2 }}
            src={category.images[0]}
          />
        ) : (
          <Box
            width={"100%"}
            height={"100%"}
            backgroundColor={"#D9D9D9"}
            borderRadius={2}
          />
        )}

        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            borderRadius: 2,
          }}
        >
          <Typography variant="h2" sx={{ color: palette.text.dark.primary }}>
            {category.name}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
}
