import { Box, Button, Divider, List, Typography } from "@mui/material";
import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { CheckoutApi } from "../api/CheckoutApi";
import { OrderApi } from "../api/OrderApi";
import { PromotionApi } from "../api/PromotionApi";
import ActionButton from "../components/cart/ActionButton";
import CartItem from "../components/cart/CartItem";
import FullScreenLoading from "../components/FullScreenLoading";
import Header from "../components/Header";
import { CartHelper } from "../helper/CartHelper";
import { getCanteenMode, getTakeawayMode } from "../helper/GlobalValueHelper";
import { modeState, orderState } from "../reducers/commonSlice";
import {
  cartItemsState,
  discountPriceState,
  finalTotalState,
  orderPickupTimeState,
  orderPromotionState,
  originTotalState,
  updateCartState,
} from "../reducers/shoppingCartSlice";

export default function ShoppingCart() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [orderRemark, setOrderRemark] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");
  const storeId = searchParams.get("store_id");
  const [promotionList, setPromotionList] = useState([]);

  // redux
  const dispatch = useDispatch();
  const globalCartItems = useSelector(cartItemsState);
  const globalOriginTotal = useSelector(originTotalState);
  const globalFinalTotal = useSelector(finalTotalState);
  const globalDiscountPrice = useSelector(discountPriceState);
  const globalOrderPromotion = useSelector(orderPromotionState);
  const globalOrder = useSelector(orderState);
  const mode = useSelector(modeState);
  const orderPickupTime = useSelector(orderPickupTimeState);

  const needContact =
    mode === getTakeawayMode() &&
    process.env.REACT_APP_ENABLE_CUSTOMER_CONTACT === "true";

  useEffect(() => {
    console.log(globalCartItems);
  }, [globalCartItems]);

  useEffect(() => {
    fetchPromotionList();
  }, []);

  const handleUpdateCartItem = (index, item, qty) => {
    const cartItems = [...globalCartItems];

    if (qty === 0) {
      cartItems.splice(index, 1);
    } else {
      cartItems[index] = {
        ...item,
        qty: qty,
      };
    }

    const {
      newCartItems,
      newFinalPrice,
      newDiscountPrice,
      newOriginTotal,
      orderPromotion,
    } = CartHelper.calculate(promotionList, cartItems, null);

    dispatch(
      updateCartState({
        cartItems: newCartItems,
        originTotal: Math.floor(newOriginTotal),
        finalTotal: Math.floor(newFinalPrice),
        discountPrice: Math.floor(newDiscountPrice),
        orderPromotion: orderPromotion,
      })
    );

    // save to local storage
    localStorage.setItem(
      "cartState",
      JSON.stringify({
        cartItems: newCartItems,
        originTotal: Math.floor(newOriginTotal),
        finalTotal: Math.floor(newFinalPrice),
        discountPrice: Math.floor(newDiscountPrice),
        orderPromotion: orderPromotion,
      })
    );
  };

  const fetchPromotionList = async () => {
    const response = await PromotionApi.list(1, -1, []);
    setPromotionList(response.data);
  };

  const onClickAddMore = () => {
    let data = null;

    if (storeId) {
      data = {
        store_id: storeId,
      };
    } else if (orderId) {
      data = {
        order_id: orderId,
      };
    }
    navigate({
      pathname: "/category",
      search: createSearchParams(data).toString(),
    });
  };

  const submitOrder = async () => {
    if (mode == getCanteenMode()) {
      submitCanteenOrder();
    } else if (mode == getTakeawayMode()) {
      let data = null;

      if (storeId) {
        data = {
          store_id: storeId,
        };
      } else if (orderId) {
        data = {
          order_id: orderId,
        };
      }
      navigate({
        pathname: "/contact",
        search: createSearchParams(data).toString(),
      });
    } else {
      toast.error("Order mode is not supported");
    }
  };

  const submitCanteenOrder = async () => {
    try {
      const orderItems = [];

      globalCartItems.forEach((item) => {
        orderItems.push({
          variantId: item.id,
          optionData: item.optionData,
          qty: item.qty,
          finalPrice: item.finalPrice,
          originPrice: item.finalPrice,
          promotions: [],
        });
      });

      console.log(orderItems);

      setFullScreenLoading(true);

      const orderStatus = await checkOrderStatus();

      if (!orderStatus) {
        location.reload();
        return;
      }

      const success = await CheckoutApi.createOrder(globalOrder.id, orderItems);

      setFullScreenLoading(false);

      if (success) {
        navigate({
          pathname: "/order-list",
          search: createSearchParams({
            order_id: orderId,
          }).toString(),
        });

        // reset cart
        dispatch(
          updateCartState({
            cartItems: [],
            originTotal: 0,
            finalTotal: 0,
            discountPrice: 0,
            orderPromotion: [],
          })
        );
      }

      //clear local storage and cart
      localStorage.removeItem("cartState");
      dispatch(
        updateCartState({
          cartItems: [],
          originTotal: 0,
          finalTotal: 0,
          discountPrice: 0,
          orderPromotion: [],
        })
      );
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const checkOrderStatus = async () => {
    const decodedOrderId = decodeURIComponent(Base64.decode(orderId));
    const response = await OrderApi.details(decodedOrderId);
    if (!response || response.status != "pending") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box>
      {fullScreenLoading && <FullScreenLoading open={fullScreenLoading} />}
      <Header title={t("layout.shopping_cart")} />

      <Box px={2}>
        <Box py={2}>
          <Typography variant={"h3"}> {t("layout.table_number")}: 1</Typography>
        </Box>
        <Divider />

        {globalCartItems && globalCartItems.length > 0 ? (
          <List display={"flex"}>
            {globalCartItems.map((item, index) => (
              <CartItem
                key={index}
                index={index}
                item={item}
                onUpdate={handleUpdateCartItem}
              />
            ))}
          </List>
        ) : (
          <Box display={"flex"} flex={1} justifyContent={"center"} p={2}>
            <Typography>{t("layout.no_item_in_cart")}</Typography>
          </Box>
        )}

        <Button variant="text" onClick={onClickAddMore}>
          {t("layout.add_more")}
        </Button>
      </Box>

      <ActionButton
        contact={needContact}
        checkout={!needContact}
        onSubmit={submitOrder}
      />
    </Box>
  );
}
