import axios from "../helper/AxiosHelper";

const details = async (id) => {
  const response = await axios.get(`/order/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.record.id,
    status: result.record.status,
    orderNum: result.record.order_num,
    memberId: result.record.member_id,
    storeId: result.record.store_id,
    tableId: result.record.table_id,
    originTotal: result.record.origin_total,
    finalTotal: result.record.final_total,
    paidTotal: result.record.paid_total,
    change: result.record.change,
    payStatus: result.record.pay_status,
    shipStatus: result.record.ship_status,
    remark: result.record.remark,
    saledBy: result.record.saled_by,
    createdBy: result.record.created_by,
    updatedAt: result.record.updated_at,
    member: result.record.member
      ? {
          id: result.record.member,
          username: result.record.member.username,
          email: result.record.member.email,
          firstName: result.record.member.first_name,
          lastName: result.record.member.last_name,
          phoneNumber: result.record.member.last_name,
        }
      : null,
    saled: result.record.saled,
    items: result.record.order_items.map((variant) => {
      const product = result.record.order_products.find(
        (product) => product.id == variant.product_id
      );
      return {
        id: variant.id,
        orderId: variant.order_id,
        productId: variant.product_id,
        variantId: variant.variant_id,
        sku: variant.sku,
        qty: variant.qty,
        barcode: variant.barcode,
        finalPrice: variant.final_price,
        originTotal: variant.origin_price,
        optionValues: variant.option_values,
        optionMapping: variant.option_values,
        promotion: variant.promotion,
        product: product,
        optionData: variant.option_data.map((option) => {
          return {
            optionValueId: option.option_value_id,
            tags: option.tags,
          };
        }),
      };
    }),
    payments: result.record.order_payments
      ? result.record.order_payments.map((payment) => {
          return {
            id: payment.id,
            code: payment.code,
            name: payment.name,
            amount: payment.amount,
          };
        })
      : [],
    promotions: result.record.promotions ? result.record.promotions : [],
  };
};

const findOrderByOrderNum = async (orderNum) => {
  const response = await axios.get(`/findByOrderNum/${orderNum}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    status: result.status,
    orderNum: result.order_num,
    memberId: result.member_id,
    storeId: result.store_id,
    tableId: result.table_id,
    originTotal: result.origin_total,
    finalTotal: result.final_total,
    paidTotal: result.paid_total,
    change: result.change,
    payStatus: result.pay_status,
    shipStatus: result.ship_status,
    remark: result.remark,
    saledBy: result.saled_by,
    createdBy: result.created_by,
    updatedAt: result.updated_at,
    customerEmail: result.customer_email,
    customerName: result.customer_name,
    customerPhone: result.customer_phone_num,
    member: result.member
      ? {
          id: result.member,
          username: result.member.username,
          email: result.member.email,
          firstName: result.member.first_name,
          lastName: result.member.last_name,
          phoneNumber: result.member.last_name,
        }
      : null,
    saled: result.saled,
    items: result.order_items.map((variant) => {
      const product = result.order_products.find(
        (product) => product.id == variant.product_id
      );
      return {
        id: variant.id,
        orderId: variant.order_id,
        productId: variant.product_id,
        variantId: variant.variant_id,
        sku: variant.sku,
        qty: variant.qty,
        barcode: variant.barcode,
        finalPrice: variant.final_price,
        originTotal: variant.origin_price,
        optionValues: variant.option_values,
        optionMapping: variant.option_values,
        promotion: variant.promotion,
        product: product,
        optionData: variant.option_data
          ? variant.option_data.map((option) => {
              return {
                optionValueId: option.option_value_id,
                tags: option.tags,
              };
            })
          : [],
      };
    }),
    payments: result.order_payments
      ? result.order_payments.map((payment) => {
          return {
            id: payment.id,
            code: payment.code,
            name: payment.name,
            amount: payment.amount,
          };
        })
      : [],
    promotions: result.promotions ? result.promotions : [],
    pickupTime: result.pickup_time,
  };
};

export const OrderApi = {
  details,
  findOrderByOrderNum,
};
