import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  Button, IconButton, Typography
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import palette from "../../styles/theme/palette";

export default function AddToCartAction({
  product,
  selectedVariant,
  selectedTagList,
  onSubmit,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [qty, setQty] = useState(1);

  const handleAddToCart = () => {
    onSubmit(product, selectedVariant, qty, selectedTagList);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow:
          "0px -12px 5px rgb(195 196 203 / 3%), 0px -7px 4px rgb(195 196 203 / 10%), 0px -3px 3px rgb(195 196 203 / 17%), 0px -1px 2px rgb(195 196 203 / 20%), 0px 0px 0px rgb(195 196 203 / 20%)",
        borderRadius: "12px 12px 0px 0px",
      }}
    >
      <Box
        sx={{
          backgroundColor: palette.background.paper,
          boxShadow: 1,
          p: 2,
          flexGrow: 1,
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <IconButton
            color={qty > 1 ? "primary" : "disabled"}
            onClick={() => {
              if (qty > 1) {
                setQty(qty - 1);
              }
            }}
          >
            <RemoveCircleIcon />
          </IconButton>

          <Typography variant="h4">{qty}</Typography>
          <IconButton
            color="primary"
            onClick={() => {
              setQty(qty + 1);
            }}
          >
            <AddCircleIcon />
          </IconButton>
        </Box>

        <Box>
          <Button
            variant={"contained"}
            color={
              selectedVariant && selectedVariant.length > 0
                ? "primary"
                : "inherit"
            }
            onClick={() => {
              handleAddToCart();
            }}
          >
            {t("layout.add_to_cart")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
