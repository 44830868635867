import axios from "../helper/AxiosHelper";

const details = async (id) => {
  const response = await axios.get(`/table/show/${id}`);
  const result = response.data.result;
  return {
    id: result.record.id,
    name: result.record.name,
    description: result.record.description,
  };
};

export const TableApi = {
  details,
};
