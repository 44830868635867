import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import palette from "../styles/theme/palette";

export default function ValidationTextField({
  name,
  type,
  title,
  errorText,
  disabled,
  onChange,
  onBlur,
  touched,
  onKeyDown,
  size,
  required,
  multiline,
  minRows,
  value,
  variant,
}) {
  const [validate, setValidate] = useState(true);

  useEffect(() => {
    if (errorText && touched) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  });

  return (
    <Box>
      <TextField
        id={name}
        name={name}
        type={type}
        label={title}
        size={size}
        multiline={multiline}
        minRows={minRows}
        value={value || ""}
        required={required}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        sx={
          validate
            ? {}
            : { input: { backgroundColor: palette.error.secondary } }
        }
        error={!validate}
        helperText={!validate && errorText}
        fullWidth
        variant={variant || "outlined"}
        onKeyDown={onKeyDown}
      />
    </Box>
  );
}
