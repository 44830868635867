import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  Button
} from "@mui/material";
import { useTranslation } from "react-i18next"; // Functional Components
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import palette from "../../styles/theme/palette";

export default function OrderDetailButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");
  const storeId = searchParams.get("store_id");

  const handleClick = () => {
    let data = null;

    if (storeId) {
      data = {
        store_id: storeId,
      };
    } else if (orderId) {
      data = {
        order_id: orderId,
      };
    }

    navigate({
      pathname: "/order-list",
      search: createSearchParams(data).toString(),
    });
  };

  return (
    <Button
      aria-haspopup="true"
      onClick={handleClick}
      color="inherit"
      sx={{
        minWidth: "auto",
        p: 1,
        backgroundColor: palette.background.light,
      }}
      variant={"contained"}
      disableElevation
    >
      <ReceiptIcon sx={{ color: palette.text.primary }} />
    </Button>
  );
}
