import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./reducers/commonSlice";
import shoppingCartSlice from "./reducers/shoppingCartSlice";

export const store = configureStore({
  reducer: {
    common: commonReducer,
    shoppingCart: shoppingCartSlice,
  },
});
