import LanguageIcon from "@mui/icons-material/Language";
import {
  Box, Button, Menu,
  MenuItem
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Functional Components
import {
  getLocalStorageLocale,
  setLocalStorageLocale
} from "../helper/LocalStorageHelper";
import palette from "../styles/theme/palette";

export default function LanguageDropdown() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageList] = useState([
    { name: "English", value: "en" },
    { name: "繁體中文", value: "zh-HK" },
  ]);
  const selectedLang = getLocalStorageLocale();
  const open = Boolean(anchorEl);

  useEffect(() => {}, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchLang = (value) => {
    console.log("switchLang", value);
    i18n.changeLanguage(value);
    setLocalStorageLocale(value);
    handleClose();
  };

  return (
    <Box component={"div"}>
      <Button
        aria-label="switch language"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        sx={{
          minWidth: "auto",
          p: 1,
          backgroundColor: palette.background.light,
        }}
        variant={"contained"}
        disableElevation
      >
        <LanguageIcon sx={{ color: palette.text.primary }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {languageList.map((lang) => (
          <MenuItem key={lang.value} onClick={() => switchLang(lang.value)}>
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
