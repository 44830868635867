import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatHelper } from "../../helper/FormatHelper";
import palette from "../../styles/theme/palette";
export default function ProductVariantCard({
  index,
  product,
  option,
  selectedVariant,
  selectedTagList,
  onChangeValue,
  onChangeTag,
}) {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const [opened, setOpened] = useState([]);

  const onChangeOption = (index, value) => {
    onChangeValue(index, value);

    const selectedOption = option.values.find((item) => item.id == value);

    selectedOption.tagset.forEach((tag) => {
      onChangeTag(selectedOption, tag, tag.tagList[0]);
    });
  };

  const handleOpenCollapse = (index) => {
    const newOpened = [...opened];
    newOpened[index] = !newOpened[index];
    setOpened(newOpened);
  };

  return (
    <Box sx={{ flexGrow: 1, position: "relative", width: "100%" }}>
      <Card
        variant="outlined"
        sx={{ backgroundColor: palette.background.paper }}
      >
        <CardHeader
          id={clicked ? "" : "value-required"}
          title={option.name}
          sx={clicked ? {} : { backgroundColor: "#D3F8D3" }}
          action={
            <Chip
              size="small"
              variant={clicked ? "outlined" : "contained"}
              color={clicked ? "default" : "primary"}
              label={
                <Typography
                  variant="h5"
                  color={clicked ? palette.text.primary : "white"}
                >
                  {clicked ? t("layout.filled") : t("layout.required")}
                </Typography>
              }
            />
          }
        />
        <Divider color={"primary"} />
        <CardContent
          sx={
            clicked
              ? {
                  p: 0,
                  "&:last-child": {
                    paddingBottom: 0,
                  },
                }
              : {
                  backgroundColor: "#F6FEF6",
                  p: 0,
                  "&:last-child": {
                    paddingBottom: 0,
                  },
                }
          }
        >
          <RadioGroup
            sx={{ display: "flex", flexDirection: "row" }}
            onChange={(event) => {
              setClicked(true);
              onChangeOption(index, event.target.value);
            }}
          >
            {option.values.map((optionValue, valueIndex) => (
              <Box key={valueIndex} width={"100%"}>
                <FormControlLabel
                  value={optionValue.id}
                  disableTypography
                  labelPlacement="start"
                  disabled={product?.variants.some(
                    (variant) =>
                      variant.optionValues.includes(optionValue.id) &&
                      !variant.isAvailable
                  )}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    m: 0,
                    flex: 1,
                    "&.Mui-disabled": {
                      opacity: 0.5,
                    },
                  }}
                  control={
                    <Radio
                      onClick={() => {
                        handleOpenCollapse(valueIndex);
                      }}
                    />
                  }
                  label={
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      flexDirection={"row"}
                      flex={1}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="p2" pl={2}>
                          {optionValue.name}
                        </Typography>
                        {optionValue.tagset.length > 0 ? (
                          opened[valueIndex] ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        ) : null}
                      </Box>
                      <Typography variant="p3" color={palette.text.secondary}>
                        {optionValue.addonPrice > 0
                          ? ` + ${FormatHelper.formatAmount(
                              optionValue.addonPrice
                            )}`
                          : t("layout.free")}
                      </Typography>
                    </Box>
                  }
                />

                <Collapse
                  in={
                    opened[valueIndex] &&
                    selectedVariant[index] == optionValue.id
                  }
                >
                  {optionValue.tagset.map((tag, tagIndex) => (
                    <Box key={tagIndex}>
                      <Divider />
                      <Box
                        display={"flex"}
                        flex={1}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        p={1}
                      >
                        <Typography variant="p2" pl={2}>
                          {t("layout.select")}
                          {tag.name}
                        </Typography>
                        <Divider />
                      </Box>
                      <Box>
                        <RadioGroup
                          defaultValue={tag.tagList[0] || ""}
                          onChange={(event) => {
                            onChangeTag(optionValue, tag, event.target.value);
                          }}
                        >
                          {tag.tagList.map((tagValue, tagValueIndex) => (
                            <Box key={tagValueIndex}>
                              <Divider />
                              <Box
                                display={"flex"}
                                flex={1}
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                key={tagValueIndex}
                                pl={4}
                              >
                                <FormControlLabel
                                  labelPlacement="start"
                                  disableTypography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    m: 0,
                                    flex: 1,
                                    width: "100%",
                                  }}
                                  control={<Radio value={tagValue} />}
                                  label={
                                    <Box
                                      display={"flex"}
                                      alignItems={"center"}
                                      flex={1}
                                    >
                                      <Typography variant="p2" pl={2}>
                                        {tagValue}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </Box>
                            </Box>
                          ))}
                        </RadioGroup>
                      </Box>
                    </Box>
                  ))}
                </Collapse>

                {valueIndex + 1 != option.values.length && <Divider />}
              </Box>
            ))}
          </RadioGroup>
        </CardContent>
      </Card>
    </Box>
  );
}
