const typography = {
  fontFamily: ["Noto Sans TC", "sans - serif"].join(","),
  // htmlFontSize: 10,
  fontWeightBold: 900,

  h1: {
    fontSize: "1.75rem",
    fontWeight: 700,
    lineHeight: 1.35,
    letterSpacing: "-0.01em",
  },
  h2: {
    fontSize: "1.5rem",
    fontWeight: 900,
    lineHeight: 1.35,
    letterSpacing: "-0.01em",
  },
  h3: {
    fontSize: "1.25rem",
    fontWeight: 700,
    lineHeight: 1.35,
    letterSpacing: "0.01em",
  },
  h4: {
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: 1.35,
    letterSpacing: "0em",
  },
  h5: {
    fontSize: "0.625rem",
    fontWeight: 700,
    lineHeight: 1.35,
  },
  h6: {
    fontSize: "0.5rem",
    fontWeight: 700,
    lineHeight: 1.35,
  },
  p0: {
    fontSize: "1.125rem",
    fontWeight: 500,
    lineHeight: 1.35,
  },
  p1: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.35,
  },
  p2: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.35,
  },
  p3: {
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: 1.35,
  },
  p4: {
    fontSize: "0.625rem",
    fontWeight: 500,
    lineHeight: 1.35,
  },
  p5: {
    fontSize: "0.5rem",
    fontWeight: 500,
    lineHeight: 1.35,
  },
};

export default typography;
