import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

export default function InvalidPage() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        p: 2,
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h2">{t("error.order_invalid")}</Typography>
      <Typography variant="h4">{t("error.please_rescan_qrcode")}</Typography>
    </Box>
  );
}
