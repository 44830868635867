import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.get(
    `/store/query?query=${JSON.stringify(query)}`
  );
  const result = response.data.result;

  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
        address: data.address,
        phoneNumber: data.phone_number,
        inventoryId: data.inventory_id,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async (id) => {
  const response = await axios.get(`/store/show/${id}`);
  const result = response.data.result;
  return {
    id: result.record.id,
    name: result.record.name,
    address: result.record.address,
    phoneNumber: result.record.phone_number,
    inventoryId: result.record.inventory_id,
    code: result.record.code,
  };
};

export const StoreApi = {
  list,
  details,
};
