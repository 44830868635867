import {
  Box, Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate
} from "react-router-dom";
import { FormatHelper } from "../../helper/FormatHelper";
import palette from "../../styles/theme/palette";

export default function OrderTotal({ finalTotal }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignItems: "center",
        boxShadow:
          "0px -12px 5px rgb(195 196 203 / 3%), 0px -7px 4px rgb(195 196 203 / 10%), 0px -3px 3px rgb(195 196 203 / 17%), 0px -1px 2px rgb(195 196 203 / 20%), 0px 0px 0px rgb(195 196 203 / 20%)",
        borderRadius: "12px 12px 0px 0px",
        // zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette.background.paper,
          boxShadow: 1,
          px: 2,
          py: 3,
          flexGrow: 1,
          gap: 2,
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Typography variant="h2">
          {t("layout.total")}: {FormatHelper.formatAmount(finalTotal)}
        </Typography>
      </Box>
    </Box>
  );
}
