import Palette from "../palette.js";

const Element = {
  styleOverrides: {
    root: {
      color: Palette.white.main,
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: 1.35,
    },
  },
};

export default Element;
