import _axios from "axios";
import ReactSwal from "./AlertHelper";
import {
  getLocalStorageUser,
  removeLocalStorageUser,
} from "./LocalStorageHelper";
import { Translation } from "react-i18next";

function getToken() {
  try {
    const userInfo = getLocalStorageUser();
    const user = JSON.parse(userInfo);
    return user.jwt;
  } catch (error) {
    return "";
  }
}

const axios = () => {
  const token = getToken();
  const instance = _axios.create({
    baseURL: process.env.REACT_APP_API_SERVER,
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // },
    timeout: 2 * 60 * 100,
  });

  instance.interceptors.response.use(
    function (response) {
      if (!response.data.success) {
        ReactSwal.fire({
          title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
          html: response.data.message || "Unknown error",
        });
        return Promise.reject(response.data.message || "Unknown error");
      }
      return response;
    },
    function (error) {
      const statusCode = error.response.status;

      if (statusCode == 404) {
        ReactSwal.fire({
          title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
          html: "404 Not Found",
        });
        return Promise.reject(error);
      }

      if (error.response.data) {
        ReactSwal.fire({
          title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
          html: error.response.data.error,
        });
      } else {
        ReactSwal.fire({
          title: <Translation>{(t) => <>{t("error.title")}</>}</Translation>,
          html: error.message || "Unknown error",
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default axios();
