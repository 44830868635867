import moment from "moment";
import {
  getBuyAmountGetDiscountYPromotionType,
  getBuyItemXGetDiscountYPromotionType,
  getBuyXPayYPromotionType,
} from "./GlobalValueHelper";
import { arrayCompare } from "./UnitsHelper";

export const calculate = (promotionList, cartItems, member) => {
  let items = JSON.parse(JSON.stringify(cartItems)); // clone cartItems
  let promotions = [...promotionList]; // clone promotionList

  // init fields and calc the final price without promotion
  items = items.map((item) => {
    return {
      ...item,
      discountedPrice: 0,
      price: calcCartItemPrice(item),
      finalPrice: calcCartItemPrice(item) * item.qty,
      promotion: null,
    };
  });

  // filter the promotion list
  const buyXPayYPromotionList = promotions.filter(
    (promotion) => promotion.logicType == getBuyXPayYPromotionType()
  );

  const buyItemXGetDiscountPromotionList = promotions.filter(
    (promotion) => promotion.logicType == getBuyItemXGetDiscountYPromotionType()
  );

  const buyAmountGetDiscountYPromotionList = promotions.filter(
    (promotion) =>
      promotion.logicType == getBuyAmountGetDiscountYPromotionType()
  );

  let buyXpayYList = calcBuyXPayY(buyXPayYPromotionList, items, member);

  // filter the promotion list, get no promotion items for next promotion
  const noPromotionList = buyXpayYList.filter((item) => item.promotion == null);
  buyXpayYList = buyXpayYList.filter((item) => item.promotion != null);

  const buyItemXGetDiscountList = calcBuyItemXGetDiscount(
    buyItemXGetDiscountPromotionList,
    noPromotionList,
    member
  );

  const newCartItems = [...buyXpayYList, ...buyItemXGetDiscountList];

  const { finalPrice, discountPrice, originTotal, orderPromotion } =
    calcOrderPrice(buyAmountGetDiscountYPromotionList, newCartItems, member);

  return {
    newCartItems: newCartItems,
    newFinalPrice: finalPrice,
    newOriginTotal: originTotal,
    newDiscountPrice: discountPrice,
    orderPromotion: orderPromotion,
  };
};

export const mergeItems = (items) => {
  let mergedItems = [];
  let itemIds = [];
  let newItems = JSON.parse(JSON.stringify(items)); // to fix Cannot assign to read only property 'qty'
  console.log("items: ", items);

  newItems.forEach((item) => {
    // check if the item is already in the mergedItems
    let exits = false;
    mergedItems.forEach((mergeItem) => {
      if (
        mergeItem.id == item.id &&
        JSON.stringify(mergeItem.optionData) == JSON.stringify(item.optionData)
      ) {
        exits = true;
      }
    });

    if (exits) {
      let index = itemIds.indexOf(item.id);
      mergedItems[index].qty =
        parseInt(mergedItems[index].qty) + parseInt(item.qty);
    } else {
      item.qty = item.qty || 1;
      itemIds.push(item.id);
      mergedItems.push(item);
    }
  });

  // console.log("mergedItems: ", mergedItems);

  return mergedItems;
};

const calcBuyXPayY = (promotions, items, member) => {
  try {
    let havePromotionItem = [];
    let noPromotionItem = [];

    promotions = promotions.sort((a, b) =>
      a.parameter.amount < b.parameter.amount ? 1 : -1
    );

    items.forEach((item) => {
      let remainCount = parseInt(item.qty);

      promotions.forEach((promotion) => {
        if (checkStartEndDate(promotion)) {
          return;
        }

        if (checkVariants(item, promotion)) {
          return;
        }

        if (checkMemberLevel(member, promotion)) {
          return;
        }

        const count = Math.floor(remainCount / promotion.parameter.qty);

        for (let i = 0; i < count; i++) {
          let promotionItem = {
            ...item,
            qty: promotion.parameter.qty,
            finalPrice: promotion.parameter.amount,
            promotion: promotion,
          };

          havePromotionItem.push(promotionItem);
          remainCount -= parseInt(promotion.parameter.qty);
        }
      });

      if (remainCount > 0) {
        noPromotionItem.push({
          ...item,
          qty: remainCount,
          finalPrice: item.price * remainCount,
          promotion: null,
        });
      }
    });

    console.log("calcBuyXPayY: ", havePromotionItem, noPromotionItem);
    return [...havePromotionItem, ...noPromotionItem];
  } catch (error) {
    console.log("error: ", error);
  }
};

const calcBuyItemXGetDiscount = (promotions, items, member) => {
  try {
    let havePromotionItem = [];
    let noPromotionItem = [];

    promotions = promotions.sort((a, b) =>
      a.parameter.value < b.parameter.value ? 1 : -1
    );

    items.forEach((item) => {
      let havePromotion = false;

      promotions.forEach((promotion) => {
        if (checkStartEndDate(promotion)) {
          return;
        }

        if (checkVariants(item, promotion)) {
          return;
        }

        if (checkMemberLevel(member, promotion)) {
          return;
        }

        havePromotion = true;
        let price = 0;
        let finalPrice = 0;

        if (item.price - promotion.parameter.value > 0) {
          if (promotion.parameter.type == "percent") {
            price = item.price * (promotion.parameter.value / 100);
          } else {
            price = item.price - promotion.parameter.value;
          }
          finalPrice = price * item.qty;
        }

        let promotionItem = {
          ...item,
          discountedPrice: price,
          finalPrice: finalPrice,
          promotion: promotion,
        };

        havePromotionItem.push(promotionItem);
      });

      if (!havePromotion) {
        noPromotionItem.push({
          ...item,
          finalPrice: item.price * item.qty,
          promotion: null,
        });
      }
    });

    console.log(
      "calcBuyItemXGetDiscount: ",
      havePromotionItem,
      noPromotionItem
    );
    return [...havePromotionItem, ...noPromotionItem];
  } catch (error) {
    console.log("error: ", error);
  }
};

const calcOrderPrice = (promotions, items, member) => {
  let finalPrice = 0.0;
  let discountPrice = 0.0;
  let originTotal = 0.0;
  let orderPromotion = null;

  items.forEach((item) => {
    finalPrice += parseFloat(item.finalPrice);
    originTotal += parseFloat(item.finalPrice);

    promotions = promotions.sort((a, b) => {
      let aAmount = 0;
      let bAmount = 0;

      if (a.parameter.type == "percent") {
        aAmount = parseFloat(
          finalPrice - (finalPrice * a.parameter.value) / 100
        );
      } else {
        aAmount = parseFloat(a.parameter.value);
      }

      if (b.parameter.type == "percent") {
        bAmount = parseFloat(
          finalPrice - (finalPrice * b.parameter.value) / 100
        );
      } else {
        bAmount = parseFloat(b.parameter.value);
      }

      return aAmount > bAmount ? 1 : -1;
    });

    promotions.forEach((promotion) => {
      if (checkStartEndDate(promotion)) {
        return;
      }

      if (checkMemberLevel(member, promotion)) {
        return;
      }

      if (finalPrice < promotion.parameter.amount) {
        return;
      }

      orderPromotion = promotion;

      if (promotion.parameter.type == "percent") {
        discountPrice = parseFloat(
          finalPrice - (finalPrice * promotion.parameter.value) / 100
        );
      } else {
        discountPrice = parseFloat(promotion.parameter.value);
        if (finalPrice < 0) {
          finalPrice = 0;
        }
      }
    });
  });

  finalPrice -= discountPrice;

  return {
    finalPrice: finalPrice,
    discountPrice: discountPrice,
    originTotal: originTotal,
    orderPromotion: orderPromotion,
  };
};

const checkMemberLevel = (member, promotion) => {
  if (promotion.reqMemberLevel > 0) {
    if (!member || member.memberLevel < promotion.reqMemberLevel) {
      return true;
    }
  }

  return false;
};

const checkVariants = (item, promotion) => {
  const variants = promotion.parameter.variants.find(
    (variant) => variant.id === item.id
  );

  if (!variants) {
    return true;
  }

  return false;
};

const checkStartEndDate = (promotion) => {
  const now = moment();
  const startDate = moment(promotion.startAt);
  const endDate = moment(promotion.endAt);

  if (now.isBefore(startDate) || now.isAfter(endDate)) {
    return true;
  }

  return false;
};

const calcCartItemPrice = (item) => {
  let total = 0;

  let addonPrice = 0;
  for (const [attr, values] of Object.entries(item.optionMapping)) {
    addonPrice += values.addon_price;
  }

  total += parseFloat(item.product.basePrice + addonPrice);

  console.log("calcCartItemPrice: ", total);

  return total;
};

export const CartHelper = {
  calculate,
  mergeItems,
};
