import styled from "@emotion/styled";
import palette from "../../styles/theme/palette";

const FullscreenCenterBox = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  left: 0,
  top: 0,
  bottom: 0,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: palette.background.paper,
}));

export default FullscreenCenterBox;
