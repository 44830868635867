import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { OrderApi } from "../api/OrderApi";
import { StoreApi } from "../api/StoreApi";
import { TableApi } from "../api/TableApi";
import { getCanteenMode, getTakeawayMode } from "../helper/GlobalValueHelper";
import {
  modeState,
  updateModeState,
  updateOrderState,
  updateStoreState,
  updateTableState,
} from "../reducers/commonSlice";
import { orderPickupTimeState } from "../reducers/shoppingCartSlice";
import InvalidPage from "./InvalidPage";

export default function Layout() {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");
  const storeId = searchParams.get("store_id");
  const dispatch = useDispatch();
  const mode = useSelector(modeState);
  const orderPickupTime = useSelector(orderPickupTimeState);
  const navigate = useNavigate();

  const [isInvalid, setIsInvalid] = useState(true);

  useEffect(() => {
    if (!storeId && !orderId) {
      setIsInvalid(false);
      return;
    }

    if (!orderId) {
      dispatch(updateModeState(getTakeawayMode()));
      fetchStore(storeId);
    } else {
      dispatch(updateModeState(getCanteenMode()));
      fetchOrder();
    }
  }, [orderId, storeId]);

  useEffect(() => {
    if (mode == getTakeawayMode()) {
      const enableOrderPickupTime =
        process.env.REACT_APP_ENABLE_ORDER_PICKUP_TIME.toLowerCase() === "true";
      if (enableOrderPickupTime && !orderPickupTime) {
        navigate({
          pathname: "/",
          search: createSearchParams({
            store_id: storeId,
          }).toString(),
        });
        return;
      }
    }
  }, [mode]);

  const fetchOrder = async () => {
    try {
      const decodedOrderId = decodeURIComponent(Base64.decode(orderId));
      const order = await OrderApi.details(decodedOrderId);

      if (!order || order.status != "pending") {
        setIsInvalid(false);
        return;
      }

      dispatch(updateOrderState(order));

      fetchTable(order.tableId);
      fetchStore(order.storeId);
    } catch (error) {
      setIsInvalid(false);
      dispatch(updateOrderState(null));
      console.log(error);
    }
  };

  const fetchStore = async (storeId) => {
    try {
      const store = await StoreApi.details(storeId);
      dispatch(updateStoreState(store));
    } catch (error) {
      setIsInvalid(false);
      console.log(error);
    }
  };

  const fetchTable = async (tableId) => {
    try {
      const table = await TableApi.details(tableId);
      dispatch(updateTableState(table));
    } catch (error) {
      setIsInvalid(false);
      console.log(error);
    }
  };

  return <Box>{isInvalid ? <Outlet /> : <InvalidPage />}</Box>;
}
