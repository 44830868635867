import OrderDetail from "../views/OrderDetail";

const ResultRoute = {
  children: [
    {
      path: "/order/:orderNum",
      element: <OrderDetail />,
    },
  ],
};

export default ResultRoute;
