import { Box, ListItemButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormatHelper } from "../../helper/FormatHelper";
import palette from "../../styles/theme/palette";
import ImagePlaceholder from "../ImagePlaceholder";

export default function ProductCard({ product, onSelectProduct }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <ListItemButton
      divider
      sx={{ width: "100%", p: 2 }}
      onClick={() => onSelectProduct(product)}
    >
      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          // backgroundColor: "red",
        }}
      >
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography variant={"h4"}>{product.title}</Typography>
          <Typography variant={"p2"} color={palette.text.secondary}>
            {product.description}
          </Typography>
          <Typography variant={"h4"} color={"primary"}>
            {FormatHelper.formatAmount(product.basePrice)}
          </Typography>
        </Box>
        <Box
          sx={{
            borderRadius: 1,
            overflow: "hidden",
            minWidth: 56,
            width: 56,
            height: 56,
          }}
        >
          {/* <Box component={"img"} width={50} height={50} src={product.image} /> */}
          <ImagePlaceholder
            url={product.images.length > 0 ? product.images[0] : null}
            styleProps={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Box>
    </ListItemButton>
  );
}
