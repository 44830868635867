import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "created_at.DESC",
  };
  // `/product/query?query=${JSON.stringify(query)}&fields=all`
  const response = await axios.get(
    `/product/query?query=${JSON.stringify(query)}`
  );

  const result = response.data.result;
  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        title: data.title,
        type: data.type,
        basePrice: data.base_price,
        isSimple: data.is_simple,
        isAvailable: data.is_available,
        description: data.description,
        categories: data.categories,
        options: data.options,
        images: data.images,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const queryByStore = async (storeId, page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "created_at.DESC",
  };
  const response = await axios.get(
    `/product/queryStore?query=${JSON.stringify(
      query
    )}&fields=all&store=${storeId}`
  );

  const result = response.data.result;
  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        title: data.title,
        type: data.type,
        basePrice: data.base_price,
        description: data.description,
        categories: data.categories,
        options: data.options,
        images: data.images,
        isSimple: data.is_simple,
        variants: data.variants.map((variant) => {
          const optionMapping = variant.option_values;
          let optionValues = [];
          for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
            if (attrValue && attrValue.id) {
              optionValues.push(attrValue.id);
            }
          }
          return {
            id: variant.id,
            productId: variant.product_id,
            sku: variant.sku,
            stock: variant.stock,
            price: variant.price,
            barcode: variant.barcode,
            optionValues: optionValues,
            optionMapping: variant.option_values,
            isAvailable: variant.is_available,
          };
        }),
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async (id) => {
  const response = await axios.get(`/product/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.record.id,
    type: result.record.type,
    basePrice: result.record.base_price,
    isAvailable: result.record.is_available,
    title: result.record.title,
    description: result.record.description,
    categories: result.record.categories,
    inventories: result.record.inventories,
    options: result.record.options,
    variants: result.record.variants.map((variant) => {
      const optionMapping = variant.option_values;
      let optionValues = [];
      for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
        if (attrValue && attrValue.id) {
          optionValues.push(attrValue.id);
        }
      }
      return {
        id: variant.id,
        productId: variant.product_id,
        sku: variant.sku,
        stock: variant.stock,
        price: variant.price,
        barcode: variant.barcode,
        optionValues: optionValues,
        optionMapping: variant.option_values,
        isAvailable: variant.is_available,
      };
    }),
    services: result.record.services
      ? result.record.services.map((service) => {
          return {
            id: service.id,
            variantId: service.variant_id,
            quota: service.quota,
            expireType: service.expire_type,
            expireDate: service.expire_date,
            expirePeriod: service.expire_period,
          };
        })
      : [],
    images: result.record.images,
    isSimple: result.record.is_simple,
    trackInventory: result.record.track_inventory,
  };
};

const productOptionFullList = async () => {
  const response = await axios.get(`/productOption`);
  const result = response.data.result;
  return result.map((data) => {
    return {
      id: data.id,
      name: data.name,
      values: data.values.map((value) => {
        return {
          id: value.id,
          name: value.name,
          addonPrice: value.addon_price,
          tagset: value.tagset_detail.map((tag) => {
            return {
              id: tag.id,
              name: tag.name,
              tagList: tag.tag_list,
            };
          }),
        };
      }),
    };
  });
};

export const ProductApi = {
  list,
  queryByStore,
  details,
  productOptionFullList,
};
