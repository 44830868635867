import { Box, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { OrderApi } from "../api/OrderApi";
import Header from "../components/Header";
import OrderSkeleton from "../components/order/OrderSkeleton";
import OrderTotal from "../components/order/OrderTotal";
import PlaceOrderSuccess from "../components/order/PlaceOrderSuccess";
import { FormatHelper } from "../helper/FormatHelper";
import { getVariantName } from "../helper/UnitsHelper";
import { orderState, tableState } from "../reducers/commonSlice";
import palette from "../styles/theme/palette";

export default function OrderList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const globalOrder = useSelector(orderState);
  const globalTable = useSelector(tableState);

  useEffect(() => {
    if (!globalOrder) return;

    fetchOrder();
  }, [globalOrder]);

  const fetchOrder = async () => {
    try {
      setIsLoading(true);
      const data = await OrderApi.details(globalOrder.id);
      setOrder(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: palette.background.paper,
        height: "100vh",
        boxShadow:
          "0px -12px 5px rgb(195 196 203 / 3%), 0px -7px 4px rgb(195 196 203 / 10%), 0px -3px 3px rgb(195 196 203 / 17%), 0px -1px 2px rgb(195 196 203 / 20%), 0px 0px 0px rgb(195 196 203 / 20%)",
        borderRadius: "12px 12px 0px 0px",
      }}
    >
      <Header title={t("layout.receipt")} disableBack />

      {isLoading ? (
        <OrderSkeleton />
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          flex={1}
          overflow={"auto"}
          height={"100%"}
          pb={32}
        >
          <PlaceOrderSuccess order={order} />

          <Box px={2}>
            <Box py={2}>
              <Typography variant={"h3"}>
                {t("layout.table_number")}: {globalTable.name}
              </Typography>
            </Box>
            <Divider />

            {order.items.map((item, index) => (
              <Grid key={index} container spacing={1} pt={4}>
                <Grid item xs={2}>
                  <Typography variant={"h4"}> {item.qty} x</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography variant={"h4"}>
                      {item.product && item.product.title}
                    </Typography>
                    <Typography variant={"p2"} color={palette.text.secondary}>
                      {getVariantName(item)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant={"h4"} textAlign={"end"}>
                    {FormatHelper.formatAmount(item.finalPrice)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ pb: 2 }} />
                </Grid>
              </Grid>
            ))}
          </Box>
          <OrderTotal finalTotal={order.finalTotal} />
        </Box>
      )}
    </Box>
  );
}
