import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";

export default function ProductDetailsSkeleton() {
  useEffect(() => {}, []);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1} pt={1}>
      <Skeleton
        height={260}
        sx={{ mx: 1 }}
        variant="rounded"
        animation="wave"
      />
      <Skeleton height={32} sx={{ mx: 1 }} variant="rounded" animation="wave" />
      <Skeleton height={56} sx={{ mx: 1 }} variant="rounded" animation="wave" />
      <Skeleton
        height={300}
        sx={{ mx: 1 }}
        variant="rounded"
        animation="wave"
      />
    </Box>
  );
}
