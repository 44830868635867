import Welcome from "../views/Welcome";
import Store from "../views/Store";
import Layout from "../components/Layout";

const MainRoute = {
  element: <Layout />,
  children: [
    {
      path: "/",
      element: <Welcome />,
      index: true,
    },
  ],
};

export default MainRoute;
