import { Box, List } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ProductApi } from "../api/ProductApi";
import { PromotionApi } from "../api/PromotionApi";
import ActionButton from "../components/cart/ActionButton";
import Header from "../components/Header";
import ProductCard from "../components/product/ProductCard";
import ProductDetailsDrawer from "../components/product/ProductDetailsDrawer";
import ProductSkeleton from "../components/product/ProductSkeleton";
import { CartHelper } from "../helper/CartHelper";
import { arrayCompare } from "../helper/UnitsHelper";
import { storeState } from "../reducers/commonSlice";
import {
  cartItemsState,
  discountPriceState,
  finalTotalState,
  orderPromotionState,
  originTotalState,
  updateCartState,
} from "../reducers/shoppingCartSlice";

export default function Products() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [list, setList] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("category_id");
  const categoryName = searchParams.get("category_name");

  // redux
  const dispatch = useDispatch();
  const globalCartItems = useSelector(cartItemsState);
  const globalOriginTotal = useSelector(originTotalState);
  const globalFinalTotal = useSelector(finalTotalState);
  const globalDiscountPrice = useSelector(discountPriceState);
  const globalOrderPromotion = useSelector(orderPromotionState);
  const store = useSelector(storeState);

  // amount
  const [originTotal, setOriginTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [paidTotal, setPaidTotal] = useState(0);
  const [unpaidTotal, setUnpaidTotal] = useState(0);

  // modal
  const [showSelectVariantDialog, setShowSelectVariantDialog] = useState(false);

  // downloaded data
  const [productOptionList, setProductOptionList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);

  // selected values
  const [cartItems, setCartItems] = useState([]);
  const [orderPromotion, setOrderPromotion] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductOptions, setSelectedProductOptions] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedSales, setSelectedSales] = useState(null);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (!categoryId) {
      navigate("/");
    }

    downloadData();
  }, []);

  useEffect(() => {
    if (!selectedProduct) {
      return;
    }

    // default option id is 1
    // if (selectedProduct.isSimple) {
    //   addToCart(selectedProduct, selectedProduct.variants[0].optionValues);
    //   return;
    // }

    if (selectedProduct != null) {
      setShowSelectVariantDialog(true);

      const filteredOptionList = productOptionList.filter((option) => {
        return selectedProduct.options.includes(option.id);
      });

      setSelectedProductOptions(filteredOptionList);
    } else {
      setShowSelectVariantDialog(false);
    }
  }, [selectedProduct]);

  useEffect(() => {
    let paid = 0;
    let unpaid = 0;

    payments.forEach((p) => {
      paid += p.amount;
    });

    unpaid = finalTotal - paid;

    setPaidTotal(paid);
    setUnpaidTotal(unpaid);
  }, [cartItems, payments]);

  useEffect(() => {
    if (!store) return;
    fetchProducts();
  }, [store]);

  const downloadData = async () => {
    try {
      setFullScreenLoading(true);
      await fetchOptions();
      await fetchPromotionList();
      setFullScreenLoading(false);
    } catch (e) {
      setFullScreenLoading(false);
      console.log(e);
    }
  };

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      // hardcode storeId to 1
      const response = await ProductApi.list(1, -1, [
        { field: "inventories", value: 1, op: "contain" },
        { field: "categories", value: categoryId, op: "contain" },
        { field: "is_available", value: 1 },
      ]);
      setList(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOptions = async () => {
    const response = await ProductApi.productOptionFullList();
    setProductOptionList(response);
  };

  const fetchPromotionList = async () => {
    const response = await PromotionApi.list(1, -1, []);
    setPromotionList(response.data);
  };

  const onSelectProduct = (product) => {
    console.log(product);
    setSelectedProduct(product);

    const filteredOptionList = productOptionList.filter((option) => {
      return product.options.includes(option.id);
    });

    setSelectedProductOptions(filteredOptionList);

    setShowSelectVariantDialog(true);
  };

  const onCloseProductDetails = () => {
    setShowSelectVariantDialog(false);
  };

  const addToCart = (product, variantOptionValues, qty, selectedTagList) => {
    if (isLoading) {
      toast.error("Please wait for the previous action to complete");
      return;
    }

    let variant = null;
    product.variants.find((_variant) => {
      if (arrayCompare(_variant.optionValues, variantOptionValues)) {
        variant = JSON.parse(JSON.stringify(_variant)); // remove the reference, avoid change the original data
        variant.qty = qty || 1;
        variant.optionData =
          selectedTagList && selectedTagList.length > 0
            ? selectedTagList.filter((tag) => {
                return variant.optionValues.includes(tag.optionValueId);
              })
            : [];
        variant.product = {
          id: product.id,
          title: product.title,
          isSimple: product.isSimple,
          type: product.type,
          images: product.images,
          basePrice: product.basePrice,
        };
        return;
      }
    });
    console.log(variant);
    if (!variant) {
      const element = document.getElementById("value-required");
      element.scrollIntoView({
        behavior: "smooth",
      });
      // toast.error(t("layout.please_select_product_option"));
      return;
    }

    let newArr = [...globalCartItems];
    newArr.push(variant);

    calcPromotion(newArr, selectedMember); // also will set cartItems

    setSelectedProduct(null);
    setSelectedProductOptions(null);
    setShowSelectVariantDialog(false);
  };

  const calcPromotion = (newArr, member) => {
    const mergedItems = CartHelper.mergeItems(newArr);

    const {
      newCartItems,
      newFinalPrice,
      newDiscountPrice,
      newOriginTotal,
      orderPromotion,
    } = CartHelper.calculate(promotionList, mergedItems, member);

    setCartItems(newCartItems);
    setOriginTotal(Math.floor(newOriginTotal));
    setFinalTotal(Math.floor(newFinalPrice));
    setDiscountPrice(Math.floor(newDiscountPrice));
    setOrderPromotion(orderPromotion);

    updateGlobalCartState(
      newCartItems,
      Math.floor(newOriginTotal),
      Math.floor(newFinalPrice),
      Math.floor(newDiscountPrice),
      orderPromotion
    );

    // save the cart items to local storage
    // save to local storage
    localStorage.setItem(
      "cartState",
      JSON.stringify({
        cartItems: newCartItems,
        originTotal: Math.floor(newOriginTotal),
        finalTotal: Math.floor(newFinalPrice),
        discountPrice: Math.floor(newDiscountPrice),
        orderPromotion: orderPromotion,
      })
    );
  };

  const updateGlobalCartState = (
    newCartItems,
    newOriginTotal,
    newFinalPrice,
    newDiscountPrice,
    orderPromotion
  ) => {
    dispatch(
      updateCartState({
        cartItems: newCartItems,
        originTotal: newOriginTotal,
        finalTotal: newFinalPrice,
        discountPrice: newDiscountPrice,
        orderPromotion: orderPromotion,
      })
    );
  };

  return (
    <Box>
      <Header title={categoryName} />
      {isLoading && <ProductSkeleton />}
      <List display={"flex"}>
        {list.map((item) => (
          <ProductCard
            key={item.id}
            product={item}
            onSelectProduct={onSelectProduct}
          />
        ))}
      </List>
      <ProductDetailsDrawer
        product={selectedProduct}
        open={showSelectVariantDialog}
        options={selectedProductOptions}
        onClose={onCloseProductDetails}
        onSubmit={addToCart}
      />
      <Box sx={{ height: 200 }} />
      <ActionButton cart />
    </Box>
  );
}
