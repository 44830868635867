import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "priority.DESC",
  };
  const response = await axios.get(
    `/category/query?query=${JSON.stringify(query)}`
  );
  const result = response.data.result;

  return {
    data: result.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
        description: data.description,
        images: data.images,
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

export const CategoryApi = {
  list,
};
