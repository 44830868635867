import axios from "../helper/AxiosHelper";

const createOrder = async (orderId, orderItems) => {
  const data = {
    order_items: orderItems.map((item) => {
      return {
        variant_id: item.variantId,
        option_data: item.optionData.map((option) => {
          return {
            option_value_id: option.optionValueId,
            tags: option.tags,
          };
        }),
        qty: item.qty,
        final_price: item.finalPrice,
        origin_price: item.originPrice,
        promotions: item.promotions,
      };
    }),
  };
  const response = await axios.post(`/addOrderItems/${orderId}`, data);

  console.log(response);

  return response.data.success;
};

const createTakeawayOrder = async (orderData) => {
  const data = {
    customer_name: orderData.customerName,
    customer_phone_num: orderData.customerPhone,
    customer_email: orderData.customerEmail,
    store_id: orderData.storeId,
    origin_total: orderData.originTotal,
    final_total: orderData.finalTotal,
    paid_total: orderData.paidTotal,
    pickup_time: orderData.pickupTime,
    order_items: orderData.productVariants.map((variant) => {
      return {
        variant_id: variant.id,
        option_data: variant.optionData.map((option) => {
          return {
            option_value_id: option.optionValueId,
            tags: option.tags,
          };
        }),
        qty: variant.qty,
        origin_price: variant.price,
        final_price: variant.finalPrice,
        promotion_id: variant.promotion ? variant.promotion.id : null,
      };
    }),
    payment_methods: orderData.paymentMethods.map((method) => {
      return {
        code: method.code,
        amount: method.amount,
      };
    }),
    promotion_ids: orderData.promotions.length
      ? orderData.promotions.map((promotion) => promotion.id)
      : [],
    remark: orderData.remark,
  };
  const response = await axios.post("/checkoutTakeAwayOrder", data);

  return {
    id: response.data.result.id,
    storeId: response.data.result.store_id,
    memberId: response.data.result.member_id,
    member: response.data.result.member,
    payStatus: response.data.result.pay_status,
    shipStatus: response.data.result.ship_status,
    change: response.data.result.change,
    discount: response.data.result.discount,
    finalTotal: response.data.result.final_total,
    orderNum: response.data.result.order_num,
    originTotal: response.data.result.origin_total,
    paidTotal: response.data.result.paid_total,
    paymentMethods: response.data.result.order_payments,
    productVariants: response.data.result.order_items.map((variant) => {
      const product = response.data.result.order_products.find(
        (product) => product.id == variant.product_id
      );
      return {
        id: variant.id,
        orderId: variant.order_id,
        productId: variant.product_id,
        variantId: variant.variant_id,
        sku: variant.sku,
        qty: variant.qty,
        barcode: variant.barcode,
        price: variant.price,
        finalPrice: variant.final_price,
        originPrice: variant.origin_price,
        optionValues: variant.option_values,
        product: product,
        promotion: variant.promotion,
      };
    }),
    products: response.data.result.order_products,
    promotions: response.data.result.promotions.map((promotion) => {
      return {
        id: promotion.id,
        name: promotion.name,
        parameter: promotion.parameter,
      };
    }),
    remark: response.data.result.remark,
    createdBy: response.data.result.created_by,
    createdAt: response.data.result.created_at,
  };
};

export const CheckoutApi = {
  createOrder,
  createTakeawayOrder,
};
