import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  originTotal: 0,
  finalTotal: 0,
  discountPrice: 0,
  orderPromotion: {},
  orderPickupTime: null,
};

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    updateCartState: (state, value) => {
      state.cartItems = value.payload.cartItems;
      state.originTotal = value.payload.originTotal;
      state.finalTotal = value.payload.finalTotal;
      state.discountPrice = value.payload.discountPrice;
      state.orderPromotion = value.payload.orderPromotion;
    },
    updateOrderPickupTime: (state, value) => {
      state.orderPickupTime = value.payload;
    },
  },
});

export const { updateCartState, updateOrderPickupTime } =
  shoppingCartSlice.actions;

export const cartItemsState = (state) => state.shoppingCart.cartItems;

export const originTotalState = (state) => state.shoppingCart.originTotal;

export const finalTotalState = (state) => state.shoppingCart.finalTotal;

export const discountPriceState = (state) => state.shoppingCart.discountPrice;

export const orderPromotionState = (state) => state.shoppingCart.orderPromotion;

export const orderPickupTimeState = (state) =>
  state.shoppingCart.orderPickupTime;

export default shoppingCartSlice.reducer;
