import Palette from "../palette.js";

const Element = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path": {
        stroke: Palette.primary.main,
      },
    },
  },
};

export default Element;
