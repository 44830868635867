import { createSlice } from "@reduxjs/toolkit";
import { getCanteenMode } from "../helper/GlobalValueHelper";

const initialState = {
  table: null,
  order: null,
  store: null,
  mode: getCanteenMode(), // takeaway, canteen
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    updateTableState: (state, value) => {
      state.table = value.payload;
    },
    updateOrderState: (state, value) => {
      state.order = value.payload;
    },
    updateStoreState: (state, value) => {
      state.store = value.payload;
    },
    updateModeState: (state, value) => {
      state.mode = value.payload;
    },
  },
});

export const {
  updateTableState,
  updateOrderState,
  updateStoreState,
  updateModeState,
} = commonSlice.actions;

export const tableState = (state) => state.common.table;

export const orderState = (state) => state.common.order;

export const storeState = (state) => state.common.store;

export const modeState = (state) => state.common.mode;

export default commonSlice.reducer;
