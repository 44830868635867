import {
  Box,
  Button,
  Drawer,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateOrderPickupTime } from "../../reducers/shoppingCartSlice";
import palette from "../../styles/theme/palette";
import { roundTime } from "../../helper/UnitsHelper";

export default function SelectOrderPickupTimeDrawer({
  onClose,
  open,
  onSubmit,
}) {
  const { t } = useTranslation();
  const [type, setType] = useState("now");
  const [dateList, setDateList] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const storeId = searchParams.get("store_id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstExtraTime = 10;
  const timePerSlot = 15;
  const openingTime = "12:00:00";

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    let dateList = [];
    let timeList = [];

    dateList = getDateList();

    let currentDate = roundTime(
      moment(
        `${dateList[0]} ${moment()
          .add(firstExtraTime, "minutes")
          .format("HH:mm:ss")}`
      )
    );

    // check if current time is earlier than 12 pm
    if (moment(currentDate).hours() < 12) {
      currentDate = moment(`${dateList[0]} ${openingTime}`);
    }

    timeList = getTimeList(currentDate);

    setSelectedDate(dateList[0]);
    setSelectedTime(timeList[0]);
    setDateList(dateList);
    setTimeList(timeList);
  };

  const getTimeList = (date) => {
    const timeList = [];
    const totalTime = 24 * 60;

    for (let i = 0; i < totalTime; i += timePerSlot) {
      const time = moment(date);

      const newTime = time.add(i, "minutes");

      const timeStr = newTime.format("HH:mm");

      timeList.push(timeStr);

      if (newTime.hours() >= 23 && newTime.minutes() >= 45) {
        break;
      }
    }

    return timeList;
  };

  const getDateList = () => {
    let dateList = [];

    for (let i = 0; i < 7; i++) {
      const date = moment();
      const dateStr = date.add(i, "days").format("YYYY-MM-DD");
      dateList.push(dateStr);
    }

    return dateList;
  };

  const handleChange = (event, newValue) => {
    if (!newValue) return;
    setType(newValue);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    let currentDate = null;

    if (event.target.value == moment().format("YYYY-MM-DD")) {
      currentDate = roundTime(
        moment(
          `${event.target.value} ${moment()
            .add(firstExtraTime, "minutes")
            .format("HH:mm:ss")}`
        )
      );
    } else {
      // reset time to 00:00
      currentDate = roundTime(moment(`${event.target.value} ${openingTime}`));
    }
    const timeList = getTimeList(currentDate);
    setTimeList(timeList);
    setSelectedTime(timeList[0]);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const startOrdering = () => {
    dispatch(updateOrderPickupTime(selectedDate + " " + selectedTime));
    onSubmit();
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "8px 8px 0 0",
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
    >
      <Box
        sx={{
          position: "relative",
          height: 250,
          backgroundColor: palette.background.default,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
              p: 1,
              backgroundColor: palette.background.paper,
            }}
          >
            <Button variant="text" color={"inherit"} onClick={onClose}>
              {t("layout.cancel")}
            </Button>
            <Typography variant="h4">{t("takeaway.select_time")}</Typography>
            <Button variant="text" onClick={startOrdering}>
              {t("layout.comfirm")}
            </Button>
          </Box>

          <Box
            display={"flex"}
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
            p={2}
            flex={1}
          >
            <Select fullWidth value={selectedDate} onChange={handleDateChange}>
              {dateList.map((date, index) => (
                <MenuItem key={index} value={date}>
                  {date}
                </MenuItem>
              ))}
            </Select>
            <Select fullWidth value={selectedTime} onChange={handleTimeChange}>
              {timeList.map((time, index) => (
                <MenuItem key={index} value={time}>
                  {time}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
