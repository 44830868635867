import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import LanguageDropdown from "../components/LanguageDropdown";
import OrderDetailButton from "../components/order/OrderDetailButton";
import SelectOrderPickupTimeDrawer from "../components/order/SelectOrderPickupTimeDrawer";
import FullscreenCenterBox from "../components/styled/FullscreenCenterBox";
import { getCanteenMode, getTakeawayMode } from "../helper/GlobalValueHelper";
import { modeState, storeState, tableState } from "../reducers/commonSlice";
import { orderPickupTimeState } from "../reducers/shoppingCartSlice";
import palette from "../styles/theme/palette";

export default function Welcome() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [showSelectOrderPickupTimeDrawer, setShowSelectOrderPickupTimeDrawer] =
    useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");
  const storeId = searchParams.get("store_id");
  const store = useSelector(storeState);
  const table = useSelector(tableState);
  const mode = useSelector(modeState);
  const orderPickupTime = useSelector(orderPickupTimeState);

  useEffect(() => {}, []);

  const handleStartOrdering = () => {
    if (mode == getTakeawayMode()) {
      const enableOrderPickupTime =
        process.env.REACT_APP_ENABLE_ORDER_PICKUP_TIME.toLowerCase() === "true";
      if (enableOrderPickupTime && !orderPickupTime) {
        setShowSelectOrderPickupTimeDrawer(true);
      } else {
        navigateToCategory();
      }
      return;
    }

    navigate({
      pathname: "/category",
      search: createSearchParams({
        order_id: orderId,
      }).toString(),
    });
  };

  const closeSelectOrderPickupTimeDrawer = () => {
    setShowSelectOrderPickupTimeDrawer(false);
  };

  const navigateToCategory = () => {
    navigate({
      pathname: "/category",
      search: createSearchParams({
        store_id: storeId,
      }).toString(),
    });
  };

  return (
    <Box
      sx={{
        // backgroundImage: `url(https://picsum.photos/390/800)`,
        // backgroundImage: `url(/assets/images/background.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: palette.background.paper,
      }}
    >
      <FullscreenCenterBox>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            p: 2,
          }}
        >
          <Box
            component={"img"}
            maxWidth={300}
            src={"/assets/icons/logo.png"}
          />
          <Typography
            variant={"h1"}
            sx={{ fontSize: "3.5rem", mt: 2, textAlign: "center" }}
          >
            {store ? store.name : ""}
          </Typography>

          {mode == getCanteenMode() ? (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography variant="h4" color={palette.text.secondary}>
                {t("layout.table_number")}
              </Typography>
              <Typography variant="h2">{table ? table.name : ""}</Typography>
            </Box>
          ) : (
            <Typography variant="h4" color={palette.text.secondary}>
              {t("layout.takeaway")}
            </Typography>
          )}

          <Button
            size={"large"}
            variant={"contained"}
            onClick={handleStartOrdering}
            fullWidth
            sx={{ height: 40 }}
          >
            {t("layout.start_ordering")}
          </Button>
        </Box>
      </FullscreenCenterBox>
      <Box
        sx={{
          p: 2,
          display: "flex",
          width: "100%",
          justifyContent: "end",
          gap: 1,
        }}
      >
        <LanguageDropdown />

        {mode == getCanteenMode() && <OrderDetailButton />}
      </Box>
      <SelectOrderPickupTimeDrawer
        open={showSelectOrderPickupTimeDrawer}
        onClose={closeSelectOrderPickupTimeDrawer}
        onSubmit={navigateToCategory}
      />
    </Box>
  );
}
