import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CategoryApi } from "../api/CategoryApi";
import ActionButton from "../components/cart/ActionButton";
import CategoryCard from "../components/category/CategoryCard";
import CategorySkeleton from "../components/category/CategorySkeleton";
import Header from "../components/Header";

export default function Category() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchCategory = async () => {
    try {
      setIsLoading(true);
      const filters = [
        {
          field: "show_on_ordering",
          value: 1,
        },
      ];
      const response = await CategoryApi.list(1, -1, filters);
      setList(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Header title={t("layout.categories")} />
      {isLoading && <CategorySkeleton />}
      <Box pt={1} display={"flex"} gap={1} flexDirection={"column"}>
        {list.map((item) => (
          <Box key={item.id} px={1}>
            <CategoryCard category={item} />
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 1,
        }}
      >
        <Typography>{t("layout.this_photo_for_reference_only")}</Typography>
      </Box>
      <Box sx={{ height: 200 }} />
      <ActionButton cart />
    </Box>
  );
}
