import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import palette from "../styles/theme/palette";

export default function Header({ title, disableBack, disableHome }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");
  const storeId = searchParams.get("store_id");

  const handleBack = () => {
    console.log("handleBack");
    navigate(-1);
  };

  const backToHome = () => {
    let data = null;

    if (storeId) {
      data = {
        store_id: storeId,
      };
    } else if (orderId) {
      data = {
        order_id: orderId,
      };
    }

    navigate({
      pathname: "/",
      search: createSearchParams(data).toString(),
    });
  };

  useEffect(() => {}, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" elevation={4}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            onClick={handleBack}
            disabled={disableBack}
          >
            <ArrowBackIcon
              sx={{ color: disableBack ? "transparent" : palette.text.primary }}
            />
          </IconButton>

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h3" sx={{ flexGrow: 1, textAlign: "center" }}>
              {title}
            </Typography>
            <IconButton
              size="large"
              edge="end"
              aria-label="menu"
              onClick={backToHome}
              disabled={disableHome}
            >
              <HomeIcon
                sx={{
                  color: disableHome ? "transparent" : palette.text.primary,
                }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}
