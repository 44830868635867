import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { FormatHelper } from "../../helper/FormatHelper";
import palette from "../../styles/theme/palette";
import ImagePlaceholder from "../ImagePlaceholder";
import AddToCartAction from "./AddToCartAction";
import ProductVariantCard from "./ProductVariantCard";
import { ProductApi } from "../../api/ProductApi";
import ProductDetailsSkeleton from "./ProductDetailsSkeleton";

export default function ProductDetailsDrawer({
  onClose,
  open,
  product,
  options,
  onSubmit,
}) {
  // not is a list of variant, just use mutiple option id to identify a variant
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [selectedTagList, setSelectedTagList] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!open) return;
    setSelectedVariant([]);
    fetchProductDetails();
  }, [open]);

  const fetchProductDetails = async () => {
    try {
      setIsLoading(true);
      const result = await ProductApi.details(product.id);
      // console.log("product details", result);
      if (result.isSimple) {
        setSelectedVariant([result.variants[0].optionValues[0]]);
        // console.log("selected variant", result.variants[0].id, selectedVariant);
      }

      console.log(result);
      setProductDetails(result);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeValue = (index, value) => {
    const newArr = [...selectedVariant];
    newArr[index] = parseInt(value);
    console.log(newArr);
    setSelectedVariant(newArr);
  };

  const onChangeTag = (optionValue, tag, data) => {
    // note: dont use spread operator here, it will cause the state not update
    // const newArr = [...selectedTagList];
    let newArr = selectedTagList;
    let tagData = {
      id: tag.id,
      name: tag.name,
      value: data,
    };
    let tags = [];
    let index = newArr.findIndex(
      (item) => item.optionValueId === optionValue.id
    );

    if (newArr[index]) {
      tags = newArr[index].tags;
    } else {
      tags = [];
    }

    const findIndex = tags.findIndex((item) => item.id === tag.id);
    if (findIndex > -1) {
      tags[findIndex] = tagData;
    } else {
      tags.push(tagData);
    }

    if (newArr[index]) {
      newArr[index] = { optionValueId: optionValue.id, tags: tags };
    } else {
      newArr.push({ optionValueId: optionValue.id, tags: tags });
    }

    setSelectedTagList(newArr);
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      sx={{ zIndex: theme.zIndex.drawer + 2 }}
    >
      <Box
        sx={{
          height: "100vh",
          overflow: "auto",
          backgroundColor: palette.background.default,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, left: 8, zIndex: 2 }}
        >
          <Box
            component={"img"}
            width={32}
            src={"./assets/icons/arrow_drop_down_icon.png"}
          />
        </IconButton>

        {isLoading ? (
          <ProductDetailsSkeleton />
        ) : (
          <Box>
            <Box sx={{ height: 320 }}>
              <ImagePlaceholder
                url={
                  productDetails && productDetails.images.length > 0
                    ? productDetails.images[0]
                    : null
                }
                styleProps={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>

            <Box p={2} display={"flex"} gap={1} flexDirection={"column"}>
              <Typography variant="h3">{productDetails?.title}</Typography>
              <Typography variant="p0" color={"primary"}>
                {productDetails &&
                  FormatHelper.formatAmount(productDetails.basePrice)}
              </Typography>
              <Typography variant="p2" color={"text.secondary"}>
                {productDetails?.description}
              </Typography>

              {!productDetails?.isSimple && (
                <Box pt={2} display={"flex"} flexDirection={"column"} gap={2}>
                  {options &&
                    options.map((option, index) => {
                      return (
                        <ProductVariantCard
                          key={index}
                          index={index}
                          product={productDetails}
                          option={option}
                          selectedVariant={selectedVariant}
                          selectedTagList={selectedTagList}
                          onChangeValue={onChangeValue}
                          onChangeTag={onChangeTag}
                        />
                      );
                    })}
                </Box>
              )}
            </Box>

            <Box pb={10} />

            <AddToCartAction
              product={productDetails}
              selectedVariant={selectedVariant}
              selectedTagList={selectedTagList}
              onSubmit={onSubmit}
            />
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
