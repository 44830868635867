import InfoIcon from "@mui/icons-material/Info";
import { Alert, Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CheckoutApi } from "../api/CheckoutApi";
import ActionButton from "../components/cart/ActionButton";
import FullScreenLoading from "../components/FullScreenLoading";
import Header from "../components/Header";
import SelectOrderPickupTimeDrawer from "../components/order/SelectOrderPickupTimeDrawer";
import ValidationTextField from "../components/ValidationTextField";
import { FormatHelper } from "../helper/FormatHelper";
import { getVariantName } from "../helper/UnitsHelper";
import { orderState } from "../reducers/commonSlice";
import {
  cartItemsState,
  discountPriceState,
  finalTotalState,
  orderPickupTimeState,
  orderPromotionState,
  originTotalState,
  updateCartState,
} from "../reducers/shoppingCartSlice";
import palette from "../styles/theme/palette";

const validateSchema = Yup.object().shape({
  name: Yup.string().required(
    <Translation>{(t) => t("error.please_fill_in_this_field")}</Translation>
  ),
  // email: Yup.string()
  //   .email(<Translation>{(t) => t("error.invalid_format")}</Translation>)
  //   .required(
  //     <Translation>{(t) => t("error.please_fill_in_this_field")}</Translation>
  //   ),
  phoneNum: Yup.number().required(
    <Translation>{(t) => t("error.please_fill_in_this_field")}</Translation>
  ),
});

export default function Contact() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const storeId = searchParams.get("store_id");
  const [showSelectOrderPickupTimeDrawer, setShowSelectOrderPickupTimeDrawer] =
    useState(false);

  // redux
  const globalCartItems = useSelector(cartItemsState);
  const globalOriginTotal = useSelector(originTotalState);
  const globalFinalTotal = useSelector(finalTotalState);
  const globalDiscountPrice = useSelector(discountPriceState);
  const globalOrderPromotion = useSelector(orderPromotionState);
  const globalOrder = useSelector(orderState);
  const orderPickupTime = useSelector(orderPickupTimeState);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNum: "",
      remark: "",
    },
    validateOnMount: true,
    validationSchema: validateSchema,
  });

  useEffect(() => {}, []);

  const handleSubmit = async () => {
    try {
      formik.setTouched({
        name: true,
        // email: true,
        phoneNum: true,
      });

      formik.validateForm();

      if (!formik.isValid) return;

      const orderData = {
        customerName: formik.values.name,
        customerPhone: formik.values.phoneNum,
        customerEmail: formik.values.email,
        storeId: storeId,
        originTotal: globalOriginTotal,
        finalTotal: globalFinalTotal,
        paidTotal: globalFinalTotal,
        productVariants: globalCartItems,
        paymentMethods: [],
        promotions: globalOrderPromotion ? [globalOrderPromotion] : [], // for one promotion only
        remark: formik.values.remark,
        pickupTime: orderPickupTime,
      };

      console.log(orderData);
      setFullScreenLoading(true);

      const response = await CheckoutApi.createTakeawayOrder(orderData);

      if (response && response.orderNum) {
        //clear local storage and cart
        localStorage.removeItem("cartState");
        dispatch(
          updateCartState({
            cartItems: [],
            originTotal: 0,
            finalTotal: 0,
            discountPrice: 0,
            orderPromotion: [],
          })
        );
        navigate(
          `/order/${encodeURIComponent(Base64.encode(response.orderNum))}`,
          { replace: true }
        );
        return;
      }

      console.log(response);
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const openSelectOrderPickupTimeDrawer = () => {
    setShowSelectOrderPickupTimeDrawer(true);
  };

  const closeSelectOrderPickupTimeDrawer = () => {
    setShowSelectOrderPickupTimeDrawer(false);
  };

  return (
    <Box>
      <Header title={t("takeaway.contact")} />
      {fullScreenLoading && <FullScreenLoading open={fullScreenLoading} />}
      <Box
        sx={{
          backgroundColor: palette.background.paper,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Alert
            variant={"filled"}
            sx={{ backgroundColor: palette.background.default }}
            icon={<InfoIcon color={"primary"} fontSize={"large"} />}
          >
            <Typography variant="h4">
              我們會跟據您的電話號碼和電郵聯絡您以確認訂單和自取時間
            </Typography>
          </Alert>
        </Box>

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            flex: 1,
          }}
        >
          <ValidationTextField
            name={"name"}
            type={"text"}
            variant={"standard"}
            required
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("takeaway.name")}
            errorText={formik.errors.name}
            touched={formik.touched.name}
          />

          {/* <ValidationTextField
            name={"email"}
            type={"text"}
            variant={"standard"}
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("takeaway.email")}
            errorText={formik.errors.email}
            touched={formik.touched.email}
          /> */}
          <ValidationTextField
            name={"phoneNum"}
            type={"text"}
            variant={"standard"}
            required
            value={formik.values.phoneNum}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("takeaway.phone")}
            errorText={formik.errors.phoneNum}
            touched={formik.touched.phoneNum}
          />
          <ValidationTextField
            name={"remark"}
            type={"text"}
            variant={"standard"}
            value={formik.values.remark}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title={t("takeaway.remark")}
            errorText={formik.errors.remark}
            touched={formik.touched.remark}
            multiline
            minRows={4}
          />
        </Box>
      </Box>

      <Box
        display={"flex"}
        // alignItems={"center"}
        // justifyContent={"center"}
        flexDirection={"column"}
        px={2}
        py={4}
        mt={2}
        sx={{ backgroundColor: palette.background.paper }}
      >
        <Typography variant="h3">{t("takeaway.pickup_time")}</Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          pt={2}
        >
          <Typography variant="h3">{orderPickupTime}</Typography>
          <Button
            onClick={() => {
              openSelectOrderPickupTimeDrawer();
            }}
          >
            {t("layout.edit")}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          p: 2,
          backgroundColor: palette.background.paper,
        }}
      >
        {globalCartItems.map((item, index) => (
          <Grid key={index} container spacing={1} pt={4}>
            <Grid item xs={2}>
              <Typography variant={"h4"}> {item.qty} x</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Typography variant={"h4"}>
                  {item.product && item.product.title}
                </Typography>
                <Typography variant={"p2"} color={palette.text.secondary}>
                  {getVariantName(item)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={"h4"} textAlign={"end"}>
                {FormatHelper.formatAmount(item.finalPrice)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ pb: 2 }} />
            </Grid>
          </Grid>
        ))}

        <Box display={"flex"} justifyContent={"space-between"} pt={2}>
          <Typography variant="h3">{t("layout.total")}</Typography>

          <Typography variant="h3"> {globalFinalTotal}</Typography>
        </Box>
      </Box>

      <Box pb={30}></Box>

      <ActionButton checkout onSubmit={handleSubmit} />

      <SelectOrderPickupTimeDrawer
        open={showSelectOrderPickupTimeDrawer}
        onClose={closeSelectOrderPickupTimeDrawer}
        onSubmit={closeSelectOrderPickupTimeDrawer}
      />
    </Box>
  );
}
